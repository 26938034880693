import React from 'react';
import PropTypes from "prop-types";
import RichText from '../richText';



const PageHero = ({ data, children }) => {

      return (

            <>

                  <div className="c-page-hero">

                        <div className="c-page-hero__top js-header-waypoint" data-logo="light" data-nav="light">
                              <div className="o-wrapper">
                                    {data.caption && <h2 className="c-page-hero__kicker c-heading-group__kicker">{data.caption[0].text}</h2>}
                                    <p className="c-page-hero__title c-heading-group__title">{data.title[0].text}</p>
                              </div>
                        </div>

                        <div className="c-page-hero__waypoint js-header-waypoint" data-logo="dark" data-nav="dark" />

                        {data.description &&
                              <div className="o-wrapper">
                                    <div className="c-page-hero__box o-align-middle">
                                          <div className="o-align-middle__aligner">
                                                <div className="c-page-hero__description u-serif">
                                                      <RichText render={data.description} />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        }

                        
                        {children}


                  </div>



            </>


      );
}

PageHero.propTypes = {
      children: PropTypes.node,
      data: PropTypes.object
}

export default PageHero;